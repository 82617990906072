import { Organization, WithContext } from 'schema-dts';

/**
 * Generates proper schema.org JSON-LD for an Organization.
 */
export function getOrganization(name: string, url: string, logo: string): WithContext<Organization> {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name,
    url,
    logo,
    'parentOrganization': {
      '@type': 'Organization',
      'name': 'Hultafors Group',
      'url': 'https://www.hultaforsgroup.com',
      'logo': 'https://www.hultaforsgroup.com/assets/gfx/logo.png',
    },
  };
}
